<template>
    <div class="mainPage">
        <div class="mainPageContent">
            <div class="contentPanel">
                <div class="headerLogo">
                    <img src="../assets/logo_b.png"
                         alt="Osmose"
                         @click="openDashboard()" />
                </div>
                <div class="textCenter info1 mgTop12">
                    <span class="textnormal">Software Version {{ softwareVersion }}</span>
                </div>
                <div class="textCenter info1">
                    <div>Device ID:</div>
                    <div class="textnormal">{{ deviceId }}</div>
                </div>
                <div class="textCenter info1">
                    <div>Device Model:</div>
                    <div class="textnormal">{{ deviceModel }}</div>
                </div>
                <div class="contactSection mt-1">
                    <div class="textCenter info1">
                        <div>Account Email Address:</div>
                        <div class="textnormal">{{ emailId }}</div>
                    </div>
                    <div class="textCenter info1">
                        <div>License Key:</div>
                        <div class="textnormal">{{ licKey }}</div>
                    </div>
                    <div class="textCenter info1">
                        <div>Purchased:</div>
                        <div class="textnormal">{{ purchasedOn }}</div>
                    </div>
                    <div class="textCenter info1">
                        <div>Expires:</div>
                        <div class="textnormal">{{ expiresOn }}</div>
                    </div>
                    <!--<div class="textCenter info1">
                        <div>Startup Parameters:</div>
                        <div class="textnormal">{{ startupParameters }}</div>
                    </div>
                    <div class="textCenter info1">
                        <div>Startup Data:</div>
                        <div class="textnormal">
                            {{ startupData }}
                        </div>
                    </div>-->
                    <!-- <div class="textCenter">
                      <button class="btnblue txtUppercase mb-2">RENEW LICENSE</button>
                    </div> -->
                </div>
                <div class="textCenter">
                    <a href="javascript: void(0);"
                       class="backLink txtUppercase"
                       @click="onCancel()">
                        CANCEL
                    </a>
                </div>
            </div>
            <sc-loader v-show="isLoading"></sc-loader>
            <div class="bottomFixed">
                <ul class="listInline">
                    <!--<li class="listItem">
                        <a class="active" href="javascript: void(0);"> SETTINGS </a>
                    </li>-->
                    <!--<li class="listItem">
                        <a href="javascript: void(0);" @click="openSupport()">SUPPORT</a>
                    </li>-->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import LicenseAPI from "@/api/LicenseAPI";
    import Loader from "../components/Loader.vue";
    import Eruda from 'eruda';

    export default {
        name: "Settings",

        data: () => ({
            isLoading: false,
            softwareVersion: "1.9.3",
            deviceId: localStorage.getItem("deviceID"),
            deviceModel: localStorage.getItem("model"),
            emailId: "",
            licKey: "",
            purchasedOn: "",
            expiresOn: "",
            startupParameters: localStorage.getItem("startParameters"),
            startupData: localStorage.getItem("startData"),

        }),

        components: {
            "sc-loader": Loader,
        },

        mounted() {
            this.isLoading = true;
            this.getLicenseDetails();
            if (localStorage.getItem("ErudaEnabled") === "Yes")
                Eruda.init();

        },

        methods: {
            openDashboard() {
                this.$router.replace("/dashboard");
            },

            onCancel() {
                this.$router.go(-1);
            },

            openSupport() {
                this.$router.push("/support");
            },

            getLicenseDetails() {
                //this.softwareVersion = process.env.VUE_APP1.9.3;
                this.emailId = localStorage.getItem("emailID");
                this.licKey = localStorage.getItem("licenseKey");

                LicenseAPI.getLicenseDetails(this.licKey, this.emailId)
                    .then((response) => {
                        if (response) {
                            this.isLoading = false;
                            this.purchasedOn = this.formatDate(response.data.createdDate);
                            this.expiresOn = this.formatDate(response.data.expirationDate);
                        }
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            },

            formatDate(date) {
                let updatedDate = new Date(date);
                return (
                    "" +
                    (updatedDate.getMonth() + 1 > 9 ? "" : "0") +
                    (updatedDate.getMonth() + 1) +
                    "/" +
                    (updatedDate.getDate() > 9 ? "" : "0") +
                    updatedDate.getDate() +
                    "/" +
                    updatedDate.getFullYear()
                );
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .textnormal {
        font-weight: normal !important;
    }

    .popUp {
        text-align: center;
        position: fixed;
        top: 0px;
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .popUpBox {
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 0px 5px #d2e4ff;
        padding: 10px 10px 25px 10px;
        width: 230px;
        margin: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        p {
            font-weight: 600;
        }
    }

    .contactSection {
        margin: 12% 0px;
    }

    .btnRounded {
        background: #f1f1f1;
        border: 2px solid #f1f1f1;
        padding: 6px;
        border-radius: 50px;
        color: #0067ce;
        min-width: 90px;
        cursor: pointer;
    }

    .btnRoundedFull {
        background: #0067ce;
        border: 2px solid #0067ce;
        padding: 6px;
        border-radius: 50px;
        color: #fff;
        min-width: 90px;
        cursor: pointer;
    }

    .mainPage {
        height: calc(100vh - 50px);
    }

    .headerLogo {
        img {
            width: 230px;
        }
    }

    .mainPageContent {
        margin: auto;
    }

    .listInline {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
        margin: 1rem 0px;

        .listItem {
            display: inline-block;
            margin-right: 1rem;

            a {
                text-decoration: none;
            }

            .active {
                font-weight: 700;
            }
        }

        .listItem:last-child {
            margin-right: 0rem;
        }
    }

    .title1 {
        margin: 3% 0px;
        text-align: center;
        color: #003468;
        font-weight: 600;
        font-size: 20px;
    }

    .btndef {
        background: #ccc;
        border-radius: 5px;
        padding: 16px;
        color: #fff;
        font-size: 14px;
        border: 0px;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
        margin: 18px 0px;
        pointer-events: none;
    }

    .btnblue {
        background: #003468;
        border-radius: 8px;
        padding: 20px 16px;
        color: #fff;
        font-size: 16px;
        border: 0px;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
        margin: 18px 0px;
        pointer-events: all !important;
    }

    .error {
        color: #f00;
        font-size: 12px;
    }

    .bottomFixed {
        position: fixed;
        bottom: 0px;
        width: 100%;
        text-align: center;
        background: #fff;
        z-index: 1;

        .listInline a {
            color: #003468;
        }
    }

    .backLink {
        color: #003468;
        font-size: 16px;
        text-decoration: none;
        font-weight: 600;
    }

    .contentPanel {
        width: 350px;
        text-align: center;
        margin: auto;
        padding-top: 2rem;
        padding-bottom: 50px;
    }

    .info1 {
        color: #003468;
        font-size: 16px;
        font-weight: 600;
        margin: 16px 0px;
    }

    .info2 {
        color: #0067ce;
        font-size: 14px;
        font-weight: 600;
        margin: 16px 0px;
    }

    .mgTop12 {
        margin-top: 12% !important;
    }

    .mt-1 {
        margin-top: 10px;
    }
    //Tabs Only
    @media (min-width: 768px) {
        .contentPanel {
            padding-top: 5rem;
        }

        .listInline {
            margin: 1rem 0px;
        }
        //   .headerLogo{
        //   img{
        //     width: 230px;
        //     height: 48px;
        //   }
        // }
    }
    //Desktop only
    @media (min-width: 1200px) {
        .listInline {
            margin: 1rem 0px;
        }

        .contentPanel {
            padding-top: 1rem;
        }

        .title1 {
            margin: 3% 0px;
        }
    }

    //Small mobile
    @media (max-width: 374.5px) {
        .contentPanel {
            width: 90% !important;
        }
    }
</style>
